import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Events.css'; // Ensure you have your custom styles

function Events() {
  return (
    <div className="events-container">
      <h2 className="text-center my-4 custom-heading">EVENTS UPDATES</h2>
      <p className="text-center mb-4 custom-paragraph">WE EXHIBIT IN THE FOLLOWING SHOWS</p>

      <div className="container">
        <div className="row">
          <div className="col-md-4">
            <div className="event-card event-card-1">
            <img src="/VICENZARO.jpg" alt="Vicenzaoro" className="event-image" />
            </div>
          </div>
          <div className="col-md-4">
            <div className="event-card event-card-2">
              <img src="/GENEVA.jpg" alt="GemGeneve" className="event-image" />
            </div>
          </div>
          <div className="col-md-4">
            <div className="event-card event-card-3">
              <img src="/HONGKONG.jpg" alt="Jewellery & Gem Hong Kong" className="event-image" />
            </div>
          </div>
          {/* <div className="col-md-4">
            <div className="event-card event-card-4">
              <img src="/HKK.png" alt="The Original Miami Beach Antique Show" className="event-image" />
            </div>
          </div> */}
          {/* <div className="col-md-4">
            <div className="event-card event-card-5">
              <img src="/HKK.png" alt="JCK Las Vegas" className="event-image" />
            </div>
          </div> */}
          {/* <div className="col-md-4">
            <div className="event-card event-card-6">
              <img src="/HKK.png" alt="Jewellery, Gem & Technology in Dubai" className="event-image" />
            </div> */}
          </div>
          {/* <div className="col-md-4">
            <div className="event-card event-card-7">
              <img src="/VICENZAROf.png" alt="Vicenzaoro" className="event-image" />
            </div> */}
          </div>
        </div>
    //   </div>
    // </div>
  );
}

export default Events;
