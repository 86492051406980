import React from 'react';
import './Footer.css'; // Ensure your CSS file is correctly linked

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        {/* Office Address */}
        <div className="footer-column">
          <h4>Mumbai Office</h4>
          <p>DC-7260, Bharat Diamond Bourse, BKC, Bandra East, Mumbai, Maharashtra 400051</p>
          <a href="tel:+917045356688">+91 7045356688</a>
          <p><a href="mailto:info@aidiamond.in">info@aidiamond.in</a></p>
        </div>

        {/* Center Column for Logo and Social Media */}
        <div className="footer-column center-column">
          <div className="footer-logo">
            <img src="/RRLOGO.png" alt="Royal Rays BV Logo" style={{ height: '100px', marginTop: '-20px' }} />
          </div>
          <h4>Follow Us</h4>
          <div className="social-icons">
            <a href="https://www.instagram.com/royalraysbv" target="_blank" rel="noopener noreferrer"><i className="fab fa-instagram"></i></a>
            <a href="https://www.linkedin.com/in/royal-rays-bv-665417298/" target="_blank" rel="noopener noreferrer"><i className="fab fa-linkedin-in"></i></a>
            <a href="https://twitter.com/royalraysbv" target="_blank" rel="noopener noreferrer"><i className="fab fa-twitter"></i></a>
            <a href="https://wa.me/917045356688" target="_blank" rel="noopener noreferrer"><i className="fab fa-whatsapp"></i></a>
            <a href="skype:hirenmoradia?chat"><i className="fab fa-skype"></i></a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
