import React from 'react';

const Contactus = () => {
  return (
    <div style={{ backgroundColor: 'white', padding: '20px', color: 'black' }}> {/* Changed to white background and black text */}
      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '40px' }}>
      <div style={{ width: '40%' }}> {/* Adjusted width for the form container */}
  <h2 style={{ borderBottom: '1px solid #333', paddingBottom: '10px', color: 'black', fontSize: '18px' }}>
    CONTACT FORM
  </h2>
  <form action="https://formspree.io/f/xjvqqgbw" method="POST">
    <div style={{ marginBottom: '10px' }}>
      <label htmlFor="name" style={{ color: 'black', fontSize: '20px' }}>Name *</label>
      <input type="text" id="name" name="name" placeholder="Full Name" style={{ ...inputStyle, fontSize: '14px', padding: '6px', width: '100%' }} required />
    </div>
    <div style={{ marginBottom: '10px' }}>
      <label htmlFor="email" style={{ color: 'black', fontSize: '20px' }}>Email *</label>
      <input type="email" id="email" name="email" placeholder="your@email.com" style={{ ...inputStyle, fontSize: '14px', padding: '6px', width: '100%' }} required />
    </div>
    <div style={{ marginBottom: '10px' }}>
      <label htmlFor="subject" style={{ color: 'black', fontSize: '20px' }}>Subject *</label>
      <input type="text" id="subject" name="subject" placeholder="Subject" style={{ ...inputStyle, fontSize: '14px', padding: '6px', width: '100%' }} required />
    </div>
    <div style={{ marginBottom: '10px' }}>
      <label htmlFor="message" style={{ color: 'black', fontSize: '20px' }}>Your Message</label>
      <textarea id="message" name="message" placeholder="Write Your Message" style={{ ...textareaStyle, fontSize: '14px', padding: '6px', width: '100%' }} />
    </div>
    <button type="submit" style={{ ...buttonStyle, fontSize: '20px', padding: '8px 12px' }}>Send Your Message</button>
  </form>
</div>


        {/* Contact Info Style */}
        <div style={{ width: '45%' }}>
          <h2 style={{ borderBottom: '1px solid #333', paddingBottom: '10px', color: 'black', fontSize: '18px'  }}>CONTACT INFO</h2>
          
          <p style={{ fontSize: '20px' }}>
  <br />
  <i className="fas fa-map-marker-alt"></i> DC-7260, Bharat Diamond Bourse, Bandra Kurla Complex, Bandra (East), Mumbai - 400051
  <br></br>

  {/* <i className="fas fa-map-marker-alt"></i> 341, Hoveniersstraat 2, 2018 Antwerpen, Belgium. */}
</p>
{/* <p style={{ fontSize: '20px' }}>
  <br />
  <i className="fas fa-map-marker-alt"></i> 341, Hoveniersstraat 2, 2018 Antwerpen, Belgium.
</p> */}
 
  <p style={{ fontSize: '20px' }}>
    Email your issues and suggestion on the following<br/>info@aidiamond.in
  </p>
          <div style={{ width: '75%', height: '320px', marginTop: '20px', marginLeft: '0', marginRight: '25%' }}>
            <iframe
              title="Our Location"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3770.9860628326783!2d72.86135697497707!3d19.064350382137864!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7c9db48bcc7d1%3A0x52c692071ad14131!2sBharat%20Diamond%20Bourse%20-%20Mumbai!5e0!3m2!1sen!2sin!4v1700820177087"
              width="100%" 
              height="100%" 
              style={{ border: '0' }}
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  );
};

const inputStyle = {
  width: '100%',
  padding: '10px',
  margin: '6px 0',
  display: 'inline-block',
  border: '1px solid #ccc',
  backgroundColor: '#f7f7f7', // Lighter background for input
  color: 'black', // Dark text for readability
  boxSizing: 'border-box'
};

const textareaStyle = {
  ...inputStyle,
  height: '150px',
  resize: 'vertical'
};

const buttonStyle = {
   backgroundColor: 'rgb(76 83 90)', 
  color: 'white', 
  padding: '14px 20px',
  margin: '8px 0',
  border: 'none',
  cursor: 'pointer',
  width: '100%',
  opacity: '0.9'
};

export default Contactus;
